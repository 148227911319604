import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import HeroBigTextBannerSquareImage from "../../components/pages/solutions/HeroBigTextBannerSquareImage";
import SectionWithRightImage from "../../components/pages/solutions/SectionWithRightImage";
import SectionWithLeftImage from "../../components/pages/solutions/SectionWithLeftImage";
import HomeFeatured from "../../components/pages/home/HomeFeatured";
import AiScreenshot from "../../components/pages/ai/AiScreenshot";
import Shoutout from "../../components/pages/signup/Shoutout";
import FAQSection from "../../components/pages/faq/FAQSection";
import BlogHighlight from "../../components/pages/blog/BlogHighlight";
import HeaderBanner from "../../components/pages/ux-design/HeaderBanner";
import Content from "../../components/layout/Content/Content";

import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/What-s-the-difference-between-UI-and-UX.png";
import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/10-UX-laws-you-need-to-know.png";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/Trend-watch-UX-design-trends-2023.png";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;

const Wireframing = ({ data }) => (
  <Layout>
    <Meta
      title="UX Design Tool | UX Design Made Easy | Uizard"
      description="Upload wireframes, import UI screenshots, ideate collaboratively with your team and iterate at lightning speed. UX design has never been so easy."
      url="/ux-design/"
      faqschema='{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a UX design tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A UX design tool is a tool used for the design of digital products including apps, websites, and user interfaces. UX design tools support a wide range of tasks from wireframing, mockup creation, and prototyping right through to user testing, ideation, and iteration."
            }
          },
          {
            "@type": "Question",
            "name": "What is the best UX design tool?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "What UX design tool is best largely comes down to the level of skill of the user. For those with limited design experience, or for those who want to turn ideas around quickly with the support of AI design features, Uizard is the clear tool of choice. For those with more experience in UX design, advanced vector-based tools are a good option."
            }
          },
          {
            "@type": "Question",
            "name": "How do I use Uizard for UX design?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Uizard is a UX design tool made for ease of use and collaboration. To get started, simply sign up to Uizard for free and instantly take advantage of the app&apos;s wide range of AI-powered UX design features. Uizard also has a wide range of UX templates and components to really speed up the design process."
            }
          }
        ]
      }'
    />

    <HeroBigTextBannerSquareImage
      tagline="UX Design Tool"
      headline="Rapid, collaborative UX design"
      description="UX design made easy. Supercharge your productivity with AI-powered design."
      imageA={data.foundersDriveTowardSuccessBig.childImageSharp}
      imageB={data.foundersDriveTowardSuccessSmall.childImageSharp}
      imageAlt="users collaboratively designing a mobile app wireframe in uizard"
      link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
      cta="Sign up for free"
      tagAsTitle={true}
    />

    <HeaderBanner
      tagline="UX design, simplified"
      headline1="UX design made easy with the power of AI."
      headline2="Upload wireframes, import UI screenshots, ideate collaboratively with your team and iterate at lightning speed."
      imageA={data.bannerImageA.childImageSharp}
    />

    <AiScreenshot
      tagline="Screenshot to mockup"
      headline="Convert screenshots into editable mockups"
      description="Upload screenshots of your favorite apps and convert them into editable UX designs. Tweak any screenshot and bring your product vision to life."
      imageA={data.hificonversionImageA.childImageSharp}
      imageB={data.hificonversionImageB.childImageSharp}
      imageAlt="convert screen shot into editable UI mockup"
      videoName="uizard-scan-screenshot"
      link=""
    />

    <HomeFeatured />

    <SectionWithRightImage
      tagline="Real-time Collaboration"
      headline="Collaborative UX design"
      description="Working with your team on your latest web or app design has never been easier. Design together in real-time, leave comments for feedback, you can even share your project with external stakeholders."
      imageA={data.projectManagersWorkingAsTeamBig.childImageSharp}
      imageB={data.projectManagersWorkingAsTeamSmall.childImageSharp}
      imageAlt="Overview of Uizard's collaborative features"
    />

    <SectionWithLeftImage
      tagline="UX Components & Templates"
      headline="Expedite the design process"
      description="Never start a design from scratch again. Our library of UX design templates includes a whole range of themes that come with all the core UX design considerations and user flows built in!"
      imageA={data.mockupTemplateImageA.childImageSharp}
      imageB={data.mockupTemplateImageB.childImageSharp}
      imageAlt="Image showcasing a few screens available as Uizard templates"
    />

    <SectionWithRightImage
      tagline="User Journey Refinement"
      headline="Perfect your user journeys"
      description="Uizard makes it easy to add clickable user journeys to your UX prototypes. Share previews of your designs with internal and external stakeholders before you even think about moving on to development."
      imageA={data.UXProsUserFlowsBig.childImageSharp}
      imageB={data.UXProsUserFlowsSmall.childImageSharp}
      imageAlt="Image highlighting a design ux user flow made in Uizard"
    />

    <BlogHighlight
      title="UX design guides"
      blogs={[
        {
          image: blogThumbnail1,
          link: "https://uizard.io/blog/whats-the-difference-between-ui-and-ux/",
          alt: "Thumbnail for blog titled What's the difference between UI and UX?",
        },
        {
          image: blogThumbnail2,
          link: "https://uizard.io/blog/10-laws-of-ux-you-need-to-know/",
          alt: "Thumbnail for blog titled 10 UX laws you need to know",
        },
        {
          image: blogThumbnail3,
          link: "https://uizard.io/blog/trend-watch-ux-design-trends/",
          alt: "Thumbnail for blog titled Trend watch: UX design trends 2023",
        },
      ]}
    />

    <FAQSection
      title={"UX design FAQs"}
      questions={[
        {
          question: "What is a UX design tool?",
          answer:
            "A UX design tool is a tool used for the design of digital products including apps, websites, and user interfaces. UX design tools support a wide range of tasks from wireframing, mockup creation, and prototyping right through to user testing, ideation, and iteration.",
        },
        {
          question: "What is the best UX design tool?",
          answer:
            "What UX design tool is best largely comes down to the level of skill of the user. For those with limited design experience, or for those who want to turn ideas around quickly with the support of AI design features, Uizard is the clear tool of choice. For those with more experience in UX design, advanced vector-based tools are a good option.",
        },
        {
          question: "How do I use Uizard for UX design?",
          answer:
            "Uizard is a UX design tool made for ease of use and collaboration. To get started, simply sign up to Uizard for free and instantly take advantage of the app's wide range of AI-powered UX design features. Uizard also has a wide range of UX templates and components to really speed up the design process.",
        },
      ]}
    />

    <ContentExtended>
      <Shoutout
        headline="UX design made easy"
        description="Transform the way you think about UX design."
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </ContentExtended>
  </Layout>
);

export default Wireframing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(
      relativePath: { eq: "product/uizard-product-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersWorkingAsTeamBig: file(
      relativePath: {
        eq: "project-managers/ProjectManagers_WorkingAsTeam_2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1299, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    projectManagersWorkingAsTeamSmall: file(
      relativePath: {
        eq: "project-managers/ProjectManagers_WorkingAsTeam_1x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupTemplateImageA: file(
      relativePath: { eq: "comparison/screens-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupTemplateImageB: file(
      relativePath: { eq: "comparison/screens-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1008, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsUserFlowsBig: file(
      relativePath: { eq: "ux-professionals/UXPros_UserFlows_PNG_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1152, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    UXProsUserFlowsSmall: file(
      relativePath: { eq: "ux-professionals/UXPros_UserFlows_PNG.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessBig: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1116, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessSmall: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 559, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
